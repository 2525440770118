
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticProps } from 'next';
import flush from 'just-flush';
import { useQuery } from 'urql';
import { normalizeLocale, withPrepass } from '@/utils';
import { FindPageDetailByIdQueryDocument, FindTranslationsForPageByIdQueryDocument, GetReadingSettingsQueryDocument, } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import { LanguageFieldsFragment, PageFieldsFragment, PageSummaryFieldsFragment } from '@/gql/documents/fragments';
import { ContentRenderer, Layout, SeoHead } from '@/components';
type Props = {
    databaseId: number;
    preview?: boolean;
};
export default function Page({ databaseId, preview }: Props) {
    const [{ data }] = useQuery({
        query: FindPageDetailByIdQueryDocument,
        variables: { id: String(databaseId), asPreview: Boolean(preview) }
    });
    const fields = useFragment(PageFieldsFragment, data?.page);
    const summaryFields = useFragment(PageSummaryFieldsFragment, fields);
    if (!data || !fields || !summaryFields)
        return null;
    return (<Layout preview={preview} theme={fields.pageCustomFields?.theme === 'dark' ? 'dark' : 'light'} enableLines={fields.pageCustomFields?.enableLines ?? false} useFixedNavbar={fields.pageCustomFields?.enableOverlayNavbar ?? false} hideFooterDivider={fields.pageCustomFields?.hideFooterDivider ?? false} hideSidetext={fields.pageCustomFields?.hideSidetext ?? false} sidetext={fields.pageCustomFields?.sidetext?.text
            ? {
                text: fields.pageCustomFields.sidetext.text,
                placement: (fields.pageCustomFields.sidetext.placement ?? 'left') as 'left' | 'right'
            }
            : undefined}>
      <SeoHead seo={fields.seo ?? undefined}/>
      <ContentRenderer html={fields.content ?? ''} enableLines={fields?.pageCustomFields?.enableLines ?? false}/>
    </Layout>);
}
const getStaticProps: GetStaticProps = ({ params, preview, locale }) => withPrepass({
    params,
    preview,
    locale,
    propsFactory: async ({ urqlClient }) => {
        const readingSettings = await urqlClient.query(GetReadingSettingsQueryDocument, {}).toPromise();
        if (readingSettings.error)
            throw readingSettings.error;
        if (!readingSettings.data?.readingSettings?.pageOnFront) {
            return { notFound: true };
        }
        const pageAndTranslations = await urqlClient
            .query(FindTranslationsForPageByIdQueryDocument, {
            id: String(readingSettings.data.readingSettings.pageOnFront)
        })
            .toPromise();
        if (pageAndTranslations.error)
            throw pageAndTranslations.error;
        const translations = pageAndTranslations.data?.page?.translations;
        const pagesToConsider = flush([
            pageAndTranslations.data?.page,
            ...(translations && Array.isArray(translations) ? translations : [translations]),
        ]);
        const homepage = pagesToConsider.find((page) => {
            if ('language' in page) {
                const languageFields = useFragment(LanguageFieldsFragment, page.language);
                return languageFields?.code && normalizeLocale(languageFields.code) === locale;
            }
            return false;
        });
        if (!homepage || !('databaseId' in homepage)) {
            return {
                notFound: true
            };
        }
        return {
            preview: !!preview,
            databaseId: homepage.databaseId
        };
    },
    Component: Page
});

    async function __Next_Translate__getStaticProps__1948d978449__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948d978449__ as getStaticProps }
  